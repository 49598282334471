.about-section {
    position: relative;
    padding-top: 6rem;
    padding-bottom: 8rem;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }

  .svg-wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 1;
  }
  .about-section .btn {
    position: relative; /* Ensure positioning context */
    z-index: 10; /* Bring the button to the front */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .about-section {
      padding-top: 4rem;
      padding-bottom: 5rem;
    }
  }
  
  @media (max-width: 576px) {
    .about-section {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  
    .svg-wave {
      height: auto; /* Adjust wave height for mobile */
    }
  }