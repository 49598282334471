.hirringDivContainer{
    padding: 1rem;
    /* background-color: aqua; */
    box-shadow: 2px -0px 10px 1px rgb(240, 235, 235);
}
.hirringDivRow{
    /* box-shadow: 1px -1px 10px 3px rgb(186, 137, 47); */
}
.hirringDivCol{
    padding: 1rem;
    box-shadow: 2px -0px 10px 1px rgb(240, 235, 235) inherit;


}