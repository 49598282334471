.wholeDivProjectDetails{
    background: linear-gradient(293.15deg, #240046, #2C3E50);
}
.HomeScreenImageUrl{
    width: 100%;
    height: auto;
}
.mockup-images{
    width: 100%;
    height: auto;
}

@media (max-width: 576px) {
    .HomeScreenImageUrl{
        width: 100%;
        height: 80%;
    }
    .mockup-images{
        width: 100%;
        height: 50%;
    }
  }
