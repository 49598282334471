.DirectorsCard{
    width: 100%;
    height: auto;
}
.cardBodyDirectors{
    background-color: goldenrod;
    clip-path: polygon(0 15%, 70% 0, 100% 0, 100% 100%, 50% 100%, 0 90%);
}

.position-relative{
    position: relative;
    display: inline-block;
    padding: 5px;
}
.DirectorsCardImage{
    width: 100%;
    height: auto;
    

}
.Shieldbadge{
    position: absolute;
  top: 0%; 
  left: 0%;
  /* background-color: #007bff; */
  color: goldenrod;
  /* padding: 10px; */

  border-radius: 50%; 
  margin-top: 15px; 
  font-size: 3rem; 
  transform: translate(-50%, -50%); 
}