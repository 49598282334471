.cardscolDiv{
    font-family: Georgia, 'Times New Roman', Times, serif;
    transition: transform 0.9s ease;
}
.cardscolDiv:hover{
    /* box-shadow: 2px -2px 8px 2px rebeccapurple; */
    transform: scale(1.1);
}
.serviceCardsImg{
    width: 18rem;
}

