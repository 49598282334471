section{
  /* box-shadow: 1px -1px 10px 3px rgba(200, 200, 200, 0.8); */
  font-family: Georgia, 'Times New Roman', Times, serif;
}
p{
  text-align: justify;
}
/* For all pages other than home a section for  spacing same is header  */
.headerSection{
  background: rgba(255, 255, 255, 0.3); /* Slightly opaque background */
backdrop-filter: blur(10px);
height: 15vh;
}

/* Home Css               */
html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.main-section {
  width: 100vw; /* Ensure full width of the viewport */
  height: 100vh; /* Full height on laptops */
  position: relative;
  overflow: hidden; /* Avoid overflow */
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  transform: translate(-50%, -50%);
  z-index: -1;
  /* animation: fadeInOut 18s infinite; */
}

/* Video-specific animations for staggered fade effects */
/* .video1 {
  animation-delay: 0s;
}

.video2 {
  animation-delay: 3s; /* Delay to start after video1 fades out */
/* } */ 



/* Fade in and out effect */
@keyframes fadeInOut {
  0%, 100% { opacity: 0.1; }
  10%, 40% { opacity: 1; } /* Keep the video visible for a portion of the cycle */
}

.content {
  position: relative; 
  padding: 10px;
  z-index: 1; /* Ensure content is above the video */
}

.mainSectionHeading {
  font-weight: 400;
  color: rgb(209, 209, 209);
  
}

.mainSectionPara {
  color: rgb(231, 231, 231);
  font-weight: 500;
  font-size: 1.2rem;
  text-align: justify;
}
/* /////      2nd Services Section ///////// */
h2{
    /* background: linear-gradient(to right, rgb(214, 8, 118) , rgba(33, 81, 227, 0.825));
    -webkit-background-clip: text; */
    color: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    /* color: transparent; */
    font-weight: 900;
    font-family: Georgia, 'Times New Roman', Times, serif;
}



@media only screen and (max-width: 1440px) {
  .main-section {
    height: auto;
    min-height: 100vh; /* Adjust height on smaller screens */
    padding-top: 2rem;
  }

  .background-video {
    height: auto; /* Allow video to adjust height */
    min-height: 100vh; /* Ensure it covers at least the viewport height */
  }

  .content {
    padding: 10px; /* Keep padding for smaller screens */
  }
  .mainSectionHeading {
    font-size: 2rem; /* Adjust heading size for smaller screens */
  }
}

@media only screen and (max-width: 768px) {
  .main-section {
    height: auto; /* Adjust height on smaller screens */
  }

  .background-video {
    height: auto; /* Allow video to adjust height */
    min-height: 100vh; /* Ensure it covers at least the viewport height */
  }

  .content {
    padding: 10px; /* Keep padding for smaller screens */
  }
}
@media only screen and (max-width: 576px) {
  .main-section {
    height: auto; /* Adjust height on smaller screens */
    padding: 1.5rem;
  }
  .background-video {
    height: 100%; /* Allow video to adjust height */
    min-height: 100vh; /* Ensure it covers at least the viewport height */
  }
  .mainSectionHeading {
    font-size: 1.5rem; /* Adjust heading size for smaller screens */
  }

  .mainSectionPara {
    font-size: 1rem; /* Adjust paragraph size for smaller screens */
  }
}