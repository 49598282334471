.wholeContainerPortfolio{
    background: linear-gradient(293.15deg, #240046, #2C3E50);
    
}
.headerSection{
    background: linear-gradient(293.15deg, #240046, #2C3E50);
  backdrop-filter: blur(10px);
  height: 15vh;
}
.cover-images{
    width: 100%;
    height: 12rem;
}

/* Buttons  */
.blue{
    background-color: rgb(255, 0, 234);
}
.none{
    background: linear-gradient(293.15deg, #240046, #2C3E50);
}

@media (max-width: 768px) {
    .headerSection{
        background: linear-gradient(293.15deg, #240046, #2C3E50);
     backdrop-filter: blur(10px); 
       height: 0vh;
    } 
   
   } 
@media (max-width: 576px) {
    .headerSection{
        background: linear-gradient(293.15deg, #240046, #2C3E50);
     backdrop-filter: blur(10px); 
       height: 0vh;
    } 
   
   } 

