/* Logo Styling */
.logoImage {
  width: 10rem;
  background: transparent;
  border: none;
  margin-left: -50px;
}

/* Navbar Styling */
.header-transparent {
  background: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  height: 15vh;
  z-index: 10;
  color: white;
  transition: background 0.3s ease-in-out;
}

/* Frosted Glass Effect on Hover */
.header-transparent:hover {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .logoImage {
    width: 8rem;
    margin-left: 0;
  }

  .header-transparent {
    position: relative;
    background: black;
    color: white;
    padding: 10px 0;
    height: auto;
  }

  .header-transparent:hover {
    background: rgba(0, 0, 0, 0.9);
    color: white;
  }

  .navbarLinks {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .navLinks {
    color: white !important;
  }
}

@media (max-width: 576px) {
  .logoImage {
    width: 6rem;
  }

  .navbarLinks {
    gap: 0.3rem;
  }
}
