.milestoneBgSection{
    height: 50vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


    background-color: black;
}
@media (max-width: 1024px) {
    .milestoneBgSection{

     width: 100%;
     min-height: 100vh;
     height: auto;
    }
   }
@media (max-width: 768px) {
   .milestoneBgSection{
    width: 100%;
    height: 100%;
   }
  }
  
  @media (max-width: 496px) {
   .milestoneBgSection{
    width: 100%;
    height: auto;
   }
  
   
  }