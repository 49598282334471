.footerDiv{
    background-color: rgba(0, 22, 95, 0.825);
      /* clip-path: polygon(
  10% 10%, 90% 10%, /* Top side with notch */
  /* 100% 0%, 100% 100%, /* Right side */
  /* 0% 100%, 0% 0% /* Left side */
/* ); */ 

    /* border-bottom-left-radius: 20% 15%; */
     padding: 1.5rem;
     padding-top: 5rem;
    /* margin-bottom: 1rem; */ 
}

/* .footerServices{
  margin: 0rem;

} */


  
  
  
  
  
  

  
  /* footer::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    height: 100%;
    background: linear-gradient(135deg, #81b0ff, #4a90e2);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20% 100%);
    z-index: 1;
  } */
  
  
  
.footerDiv:hover{
    background-color: black;
    color: white;
}
.footerLogoImage{
    width: 8rem;
    padding: 10px;
    margin-bottom: 10px;
}


/* .footerNavLinks{
    /* background-color: rgba(0, 22, 95, 0.825); */
/* } */ 
.navLinks:hover{
    color: black;
}

