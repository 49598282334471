.cardBodyTeam{
    /* background-image: linear-gradient(to right, rgb(214, 8, 118) , rgba(33, 81, 227, 0.825)); */
    /* background-color: rgb(104, 17, 104);
    background-color: rgb(7, 17, 160); */
    padding: 20px;
    color: white;
    /* border-top-left-radius: 70% 15%;
    border-bottom-left-radius: 20% 15%; */
    clip-path: polygon(0 15%, 70% 0, 100% 0, 100% 100%, 50% 100%, 0 90%);
}
.TeamCards{
    background: transparent;
    border: none;
}
.TeamCardImage{
    /* width: 18rem;
    margin: 1rem */
    width: 100%;
    height: 50%;
    background: transparent;
    border: none;
}