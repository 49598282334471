.whole-div{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.servicesBcakgroundvideo {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit: cover;
z-index: -1;
}
.content{
  position: relative;
  z-index: 1;
}


.custom-image {
  width: 550px;
  height: 300px;
  margin: 2rem;
  /* object-fit: cover;  */
}

.custom-text {
  margin: 0rem;
}



.outer-div {
  position: relative;
  /* Positioning for absolute children */
  display: inline-block;
  /* Allows outer div to fit the inner content */
}

.inner-div {
  position: relative;
  /* Keep the image in place */
  z-index: -1;
  /* Keep inner content above the pseudo-element */
}

.outer-div::before {
  content: '';
  position: absolute;
  top: 20px;
  /* Adjust gap between border and image */
  left: 10px;
  /* Adjust gap between border and image */
  right: 10px;
  /* Adjust gap between border and image */
  bottom: 20px;
  /* Adjust gap between border and image */
  border: 2px solid #ffffff;
  /* Border color and thickness */
  border-radius: 10px;
  /* Optional: Add border radius */
  transform: rotate(5deg);
  /* Slightly tilt the border */
  z-index: 1;
  /* Place it below the inner content */
}



@media (max-width: 768px) {
  .custom-image {
    width: 70%;
    height: auto;
    margin-top: 2rem;
  }

  .custom-text {
    margin: 0rem;
  }
}

@media (max-width: 496px) {
  .custom-image {
    width: 100%;
    height: auto;
    margin: 0rem;
  }

  .custom-text {
    margin: 2rem;
  }
}